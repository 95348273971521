<template>
    <BreadCrumb :breadcrumbs="breadcrumbs" />
    <Alert :class="alertClass" v-show="alertContent" @closeAlert="alertContent = ''">{{alertContent}}</Alert>
    <Modal v-show="showModal" @closeModal="showModal = false" @confirmModal="confirmModal"/>
    <CardTable :tableBody="filteredTableBody">
        <div class="input-group w-50 float-start">
          <span class="input-group-text fa fa-search"/>
          <input type="text" class="form-control" placeholder="Search by Number or Account" v-model="term" @input="filterList">
          <span class="input-group-text fa fa-trash" @click="term=''; filterList();" style="cursor: pointer;"></span>
        </div>
        <router-link class="btn btn-primary float-end me-4" style="width: 147px;" to="/creditcard/new">New Credit Card</router-link>
    </CardTable>
</template>

<script>
import axios from "axios"
import { decode } from "../util.js"

var getContent =  function(item) {
  var content = item.name.toLowerCase().indexOf("auth") > -1 ? "<div>" + decode(item.creditcard) + "</div>" : "<div><a target='#' href='/#/creditcard/bill?creditcard=" + decode(item.creditcard) + "'>" + decode(item.creditcard) + "</a><div>";
  if (item.month && item.year) {
    content += item.month + "/" + item.year + " ";
  }
  if (item.pin) {
    content += decode(item.pin) + "</div>";
  }
  content += "<div><span class='badge bg-secondary'>" + (new Date(item.createdDate).toLocaleString()) + "</span></div>";
  return content;
}

export default {
  name: 'CreditCard',
  mounted() {
      this.getList()
  },
  data() {
    return {
      tableBody: [],
      filteredTableBody: [],
      breadcrumbs: [{ name: "Credit Card" }],
      showModal: false,
      currentId: "",
      alertContent: "",
      alertClass: "alert-danger",
      term: ""
    }
  },
  methods: {
    openModal(id) {
      this.showModal = true;
      this.currentId = id;
    },
    confirmModal() {
      axios.delete("/creditcard/" + this.currentId).then(() => {
          this.getList();
      }).catch(error => {
          this.alertContent = error.response ? error.response.data : error;
          this.alertClass = "alert-danger";
      })
      this.showModal = false;
    },
    getList() {
      axios.get("/creditcard").then(response => {
          var openModal = this.openModal;
          this.tableBody = response.data.map(item => {
            return {
              title: item.name,
              content: getContent(item),
              footer: [{ type: "link", link: "/creditcard/edit?id=" + item.id, class: "btn btn-primary btn-sm fa fa-pencil" },
                    { type: "button", click: function() { openModal(item.id) }, name: "<span class='fa fa-trash'></span>", class: "btn-danger" },
                    { type: "link", link: "/creditcard/bill/new?creditcard=" + item.creditcard, class: "btn btn-info btn-sm fa fa-money" }]
            }
          })
          this.filteredTableBody = this.tableBody;
      }).catch(error => {
          this.alertContent = error.response ? error.response.data : error;
          this.alertClass = "alert-danger";
      })
    },
    filterList() {
      if(this.term) {
        this.filteredTableBody = this.tableBody.filter((item) => 
          item.title.toLowerCase().indexOf(this.term.toLowerCase()) > -1 || item.content.toLowerCase().indexOf(this.term.toLowerCase()) > -1)
      } else {
        this.filteredTableBody = this.tableBody;
      }
    }
  }
}
</script>